//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
export default {
  name: "MainPage",
  components: {
    LandingPagePopUp
  },
  methods: {
    signIn() {
      this.$router.push({
        name: "sign-in"
      });
    },
    generateAccount() {
      this.$router.push({
        name: "generate-account"
      });
    }
  }
};